import {NgModule} from '@angular/core';
import {ThemeComponent} from './theme.component';
import {LoginComponent} from './pages/main-components/profile/login/login.component';
import {Routes, RouterModule} from '@angular/router';
import {LoginModule} from './pages/main-components/profile/login/login.module';
//import { AuthGuard } from "../auth/_guards/auth.guard";
import {AuthGuard} from '../auth.guard';

const routes: Routes = [
  {
    path: '',
    component: ThemeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'index',
        loadChildren: './pages/index/index.module#IndexModule',
      },

      // Main Sections
      // notifications
      {
        path: 'notifications/all-notifications',
        loadChildren:
          './pages/main-components/notifications/all-notifications/all-notifications.module#AllNotificationsModule',
      },
      {
        path: 'notifications/add-notification',
        loadChildren:
          './pages/main-components/notifications/add-notification/add-notification.module#AddNotificationModule',
      },
      {
        path: 'notifications/add-notification/:id',
        loadChildren:
          './pages/main-components/notifications/add-notification/add-notification.module#AddNotificationModule',
      },
      // Languages
      {
        path: 'languages/all-languages',
        loadChildren:
          './pages/sections/languages/all-languages/all-languages.module#AllLanguagesModule',
      },
      {
        path: 'languages/language',
        loadChildren:
          './pages/sections/languages/add-language/add-language.module#AddLanguageModule',
      },
      {
        path: 'languages/language/:id',
        loadChildren:
          './pages/sections/languages/add-language/add-language.module#AddLanguageModule',
      },
      // school_years
      {
        path: 'school_years/all-school_years',
        loadChildren:
          './pages/sections/school_years/all-school_years/all-school_years.module#AllSchoolYearsModule',
      },
      {
        path: 'school_years/school_year',
        loadChildren:
          './pages/sections/school_years/add-school_year/add-school_year.module#AddSchoolYearModule',
      },
      {
        path: 'school_years/school_year/:id',
        loadChildren:
          './pages/sections/school_years/add-school_year/add-school_year.module#AddSchoolYearModule',
      },

      // countries
      {
        path: 'countries/all-countries',
        loadChildren:
          './pages/sections/countries/all-countries/all-countries.module#AllCountriesModule',
      },
      {
        path: 'countries/country',
        loadChildren:
          './pages/sections/countries/add-country/add-country.module#AddCountryModule',
      },
      {
        path: 'countries/country/:id',
        loadChildren:
          './pages/sections/countries/add-country/add-country.module#AddCountryModule',
      },
      // sliders
      {
        path: 'sliders/all-sliders',
        loadChildren:
          './pages/sections/sliders/all-sliders/all-sliders.module#AllSlidersModule',
      },
      {
        path: 'sliders/slider',
        loadChildren:
          './pages/sections/sliders/add-slider/add-slider.module#AddSliderModule',
      },
      {
        path: 'sliders/slider/:id',
        loadChildren:
          './pages/sections/sliders/add-slider/add-slider.module#AddSliderModule',
      },
      // banners
      {
        path: 'banners/all-banners',
        loadChildren:
          './pages/sections/banners/all-banners/all-banners.module#AllBannersModule',
      },
      {
        path: 'banners/banner',
        loadChildren:
          './pages/sections/banners/add-banner/add-banner.module#AddBannerModule',
      },
      {
        path: 'banners/banner/:id',
        loadChildren:
          './pages/sections/banners/add-banner/add-banner.module#AddBannerModule',
      },
      // visitor banners
      {
        path: 'visitor_banners/all-visitor-banners',
        loadChildren:
          './pages/sections/visitor_banners/all-visitor-banners/all-visitor-banners.module#AllVisitorBannersModule',
      },
      {
        path: 'visitor_banners/visitor-banner',
        loadChildren:
          './pages/sections/visitor_banners/visitor-banner/visitor-banner.module#VisitorBannerModule',
      },
      {
        path: 'visitor_banners/visitor-banner/:id',
        loadChildren:
          './pages/sections/visitor_banners/visitor-banner/visitor-banner.module#VisitorBannerModule',
      },
      // stages
      {
        path: 'stages/all-stages',
        loadChildren:
          './pages/sections/stages/all-stages/all-stages.module#AllStagesModule',
      },
      {
        path: 'stages/stage',
        loadChildren:
          './pages/sections/stages/add-stage/add-stage.module#AddStageModule',
      },
      {
        path: 'stages/stage/:id',
        loadChildren:
          './pages/sections/stages/add-stage/add-stage.module#AddStageModule',
      },
      // courses
      {
        path: 'courses/all-courses',
        loadChildren:
          './pages/sections/courses/all-courses/all-courses.module#AllCoursesModule',
      },
      {
        path: 'courses/course',
        loadChildren:
          './pages/sections/courses/add-course/add-course.module#AddCourseModule',
      },
      {
        path: 'courses/course/:id',
        loadChildren:
          './pages/sections/courses/add-course/add-course.module#AddCourseModule',
      },
      {
        path: 'courses/showCourse/:id',
        loadChildren:
          './pages/sections/courses/show-course/show-course.module#ShowCourseModule',
      },
      {
        path: 'courses/showCourse/:id/:videoId',
        loadChildren:
          './pages/sections/courses/show-course/show-course.module#ShowCourseModule',
      },
      {
        path: 'courses/all-subscriptions',
        loadChildren:
          './pages/sections/courses/all-subscriptions/all-subscription.module#AllSubscriptionModule',
      },
      {
        path: 'discount-codes/all-codes',
        loadChildren:
          './pages/sections/discount-codes/all-codes/all-codes.module#AllCodesModule',
      },
      {
        path: 'discount-codes/add-codes',
        loadChildren:
          './pages/sections/discount-codes/add-codes/add-codes.module#AddCodesModule',
      },
      {
        path: 'teacher-courses-videos/all-videos',
        loadChildren:
          './pages/sections/teacher_courses_videos/all-videos/all-videos.module#AllVideosModule',
      },
      // subscription_costs
      {
        path: 'subscription_costs/all-subscription_costs',
        loadChildren:
          './pages/sections/subscription_costs/all-subscription_costs/all-subscription_costs.module#AllSubscriptionCostsModule',
      },
      {
        path: 'subscription_costs/subscription_cost',
        loadChildren:
          './pages/sections/subscription_costs/add-subscription_cost/add-subscription_cost.module#AddSubscriptionCostModule',
      },
      {
        path: 'subscription_costs/subscription_cost/:id',
        loadChildren:
          './pages/sections/subscription_costs/add-subscription_cost/add-subscription_cost.module#AddSubscriptionCostModule',
      },
      {
        path: 'subscription_costs/subscription_cost_content',
        loadChildren:
          './pages/sections/subscription_costs/subscription_cost_content/subscription_cost_content.module#SubscriptionCostContentModule',
      },
      {
        path: 'subscription_costs/subscription_cost_steps',
        loadChildren:
          './pages/sections/subscription_costs/subscription_cost_steps/subscription_cost_steps.module#SubscriptionCostStepsModule',
      },
      {
        path: 'subscription_costs/subscription_cost_videos',
        loadChildren:
          './pages/sections/subscription_costs/subscription_cost_videos/subscription_cost_videos.module#SubscriptionCostVideosModule',
      },

      // Important Reviews
      {
        path: 'important_reviews/all-questions',
        loadChildren:
          './pages/sections/important_reviews/all-questions/all-questions.module#AllQuestionsModule',
      },
      {
        path: 'important_reviews/question',
        loadChildren:
          './pages/sections/important_reviews/add-question/add-question.module#AddQuestionModule',
      },
      {
        path: 'important_reviews/question/:id',
        loadChildren:
          './pages/sections/important_reviews/add-question/add-question.module#AddQuestionModule',
      },
      // examine questions
      {
        path: 'examine_questions/all-questions',
        loadChildren:
          './pages/sections/examine_questions/all-questions/all-questions.module#AllQuestionsModule',
      },
      {
        path: 'examine_questions/question',
        loadChildren:
          './pages/sections/examine_questions/add-question/add-question.module#AddQuestionModule',
      },
      {
        path: 'examine_questions/question/:id',
        loadChildren:
          './pages/sections/examine_questions/add-question/add-question.module#AddQuestionModule',
      },

      // grades
      {
        path: 'grades/all-grades',
        loadChildren:
          './pages/sections/grades/all-grades/all-grades.module#AllGradesModule',
      },
      {
        path: 'grades/grade',
        loadChildren:
          './pages/sections/grades/add-grade/add-grade.module#AddGradeModule',
      },
      {
        path: 'grades/grade/:id',
        loadChildren:
          './pages/sections/grades/add-grade/add-grade.module#AddGradeModule',
      },
      // Printable_data
      {
        path: 'printable_data/all-printable_data',
        loadChildren:
          './pages/sections/printable_data/all-printable_data/all-printable_data.module#AllPrintableDataModule',
      },
      {
        path: 'printable_data/printable_data',
        loadChildren:
          './pages/sections/printable_data/add-printable_data/add-printable_data.module#AddPrintableDataModule',
      },
      {
        path: 'printable_data/printable_data/:id',
        loadChildren:
          './pages/sections/printable_data/add-printable_data/add-printable_data.module#AddPrintableDataModule',
      },
      // subjects
      {
        path: 'subjects/all-subjects',
        loadChildren:
          './pages/sections/subjects/all-subjects/all-subjects.module#AllSubjectsModule',
      },
      {
        path: 'subjects/subject',
        loadChildren:
          './pages/sections/subjects/add-subject/add-subject.module#AddSubjectModule',
      },
      {
        path: 'subjects/subject/:id',
        loadChildren:
          './pages/sections/subjects/add-subject/add-subject.module#AddSubjectModule',
      },
      // advantages
      {
        path: 'advantages/all-advantages',
        loadChildren:
          './pages/sections/advantages/all-advantages/all-advantages.module#AllAdvantagesModule',
      },
      {
        path: 'advantages/advantage',
        loadChildren:
          './pages/sections/advantages/add-advantage/add-advantage.module#AddAdvantageModule',
      },
      {
        path: 'advantages/advantage/:id',
        loadChildren:
          './pages/sections/advantages/add-advantage/add-advantage.module#AddAdvantageModule',
      },
      // explanations
      {
        path: 'explanations/all-explanations',
        loadChildren:
          './pages/sections/explanations/all-explanations/all-explanations.module#AllExplanationsModule',
      },
      {
        path: 'explanations/explanation',
        loadChildren:
          './pages/sections/explanations/add-explanation/add-explanation.module#AddExplanationModule',
      },
      {
        path: 'explanations/explanation/:id',
        loadChildren:
          './pages/sections/explanations/add-explanation/add-explanation.module#AddExplanationModule',
      },

      // groups
      {
        path: 'groups/all-groups',
        loadChildren:
          './pages/sections/groups/all-groups/all-groups.module#AllGroupsModule',
      },
      {
        path: 'groups/show-group/:id',
        loadChildren:
          './pages/sections/groups/show-group/show-group.module#ShowGroupModule',
      },

      // students_answers
      {
        path: 'students_answers/all-students_answers/:type',
        loadChildren:
          './pages/sections/students_answers/all-students_answers/all-students_answers.module#AllStudentsAnswersModule',
      },
      {
        path: 'students_answers/show-students_answer/:id',
        loadChildren:
          './pages/sections/students_answers/show-students_answer/show-students_answer.module#ShowStudentsAnswerModule',
      },


    // visitors_answers
    // {
    //   path: 'visitors_answers/all-visitors-answers',
    //   loadChildren:
    // './pages/sections/visitors_answers/all-visitors-answers/all-visitors-answers.module#AllVisitorsAnswersModule',
    // },
    // {
    //   path: 'visitors-answers/show_visitors_answer/:id',
    //   loadChildren:
    //     './pages/sections/visitors_answers/show-visitors-answer/show-visitors-answer.module#ShowVisitorsAnswerModule',
    // },


      // units
      {
        path: 'units/all-units/:mode',
        loadChildren:
          './pages/sections/units/all-units/all-units.module#AllUnitsModule',
      },
      {
        path: 'units/unit/:mode',
        loadChildren:
          './pages/sections/units/add-unit/add-unit.module#AddUnitModule',
      },
      {
        path: 'units/unit/:mode/:id',
        loadChildren:
          './pages/sections/units/add-unit/add-unit.module#AddUnitModule',
      },
      // questions_bank
      {
        path: 'questions_bank/all-questions_bank',
        loadChildren:
          './pages/sections/questions_bank/all-questions_bank/all-questions_bank.module#AllQuestionsBankModule',
      },
      {
        path: 'questions_bank/questions_bank',
        loadChildren:
          './pages/sections/questions_bank/add-questions_bank/add-questions_bank.module#AddQuestionsBankModule',
      },
      {
        path: 'questions_bank/questions_bank/:id',
        loadChildren:
          './pages/sections/questions_bank/add-questions_bank/add-questions_bank.module#AddQuestionsBankModule',
      },
      {
        path: 'questions_bank/show-questions_bank/:id',
        loadChildren:
          './pages/sections/questions_bank/show-questions_bank/show-questions_bank.module#ShowQuestionsBankModule',
      },
      // video_comments
      {
        path: 'video_comments/all-video-comments',
        loadChildren:
          './pages/sections/video_comments/all-video-comments/all-video-comments.module#AllVideoCommentsModule',
      },
      // video_inquiries
      {
        path: 'video_inquiries/all-video-inquiries',
        loadChildren:
          './pages/sections/video_inquiries/all-video-inquiries/all-video-inquiries.module#AllVideoInquiriesModule',
      },
      // Tests
      {
        path: 'tests/all-tests',
        loadChildren:
          './pages/sections/tests/all-tests/all-tests.module#AllTestsModule',
      },
      {
        path: 'tests/test',
        loadChildren:
          './pages/sections/tests/add-test/add-test.module#AddTestModule',
      },
      {
        path: 'tests/test/:id',
        loadChildren:
          './pages/sections/tests/add-test/add-test.module#AddTestModule',
      },
      {
        path: 'tests/show-test/:id',
        loadChildren:
          './pages/sections/tests/show-test/show-test.module#ShowTestModule',
      },

      // Tests_printable
      {
        path: 'tests_printable/all-tests_printable',
        loadChildren:
          './pages/sections/tests_printable/all-tests_printable/all-tests_printable.module#AllTestsPrintableModule',
      },
      {
        path: 'tests_printable/test_printable',
        loadChildren:
          './pages/sections/tests_printable/add-test_printable/add-test_printable.module#AddTestPrintableModule',
      },
      {
        path: 'tests_printable/test_printable/:id',
        loadChildren:
          './pages/sections/tests_printable/add-test_printable/add-test_printable.module#AddTestPrintableModule',
      },
      {
        path: 'tests_printable/show-test_printable/:id',
        loadChildren:
          './pages/sections/tests_printable/show-test_printable/show-test_printable.module#ShowTestPrintableModule',
      },

      // frequently_questions
      {
        path: 'frequently_questions/all-frequently_questions',
        loadChildren:
          './pages/sections/frequently_questions/all-frequently_questions/all-frequently_questions.module#AllFrequentlyQuestionsModule',
      },
      {
        path: 'frequently_questions/frequently_question',
        loadChildren:
          './pages/sections/frequently_questions/add-frequently_question/add-frequently_question.module#AddFrequentlyQuestionModule',
      },
      {
        path: 'frequently_questions/frequently_question/:id',
        loadChildren:
          './pages/sections/frequently_questions/add-frequently_question/add-frequently_question.module#AddFrequentlyQuestionModule',
      },

      //contact_information
      {
        path: 'contact_information/all-contact_information',
        loadChildren:
          './pages/sections/contact_information/all-contact_information/all-contact_information.module#AllContactInformationModule',
      },
      {
        path: 'contact_information/contact_information',
        loadChildren:
          './pages/sections/contact_information/add-contact_information/add-contact_information.module#AddContactInformationModule',
      },
      {
        path: 'contact_information/contact_information/:id',
        loadChildren:
          './pages/sections/contact_information/add-contact_information/add-contact_information.module#AddContactInformationModule',
      },

      //articles
      {
        path: 'articles/all-articles',
        loadChildren:
          './pages/sections/articles/all-articles/all-articles.module#AllArticlesModule',
      },
      {
        path: 'articles/article',
        loadChildren:
          './pages/sections/articles/add-article/add-article.module#AddArticleModule',
      },
      {
        path: 'articles/article/:id',
        loadChildren:
          './pages/sections/articles/add-article/add-article.module#AddArticleModule',
      },
      {
        path: 'articles/all-comments',
        loadChildren:
          './pages/sections/articles/all-comments/all-comments.module#AllCommentsModule',
      },
      //News
      {
        path: 'news/all-news',
        loadChildren:
          './pages/sections/news/all-news/all-news.module#AllNewsModule',
      },
      {
        path: 'news/new',
        loadChildren:
          './pages/sections/news/add-news/add-news.module#AddNewsModule',
      },
      {
        path: 'news/new/:id',
        loadChildren:
          './pages/sections/news/add-news/add-news.module#AddNewsModule',
      },
      //articles
      {
        path: 'teacher-guide/all-teacher-guides',
        loadChildren:
          './pages/sections/teacher_guide/all-teacher-guide/all-teacher-guide.module#AllTeacherGuideModule',
      },
      {
        path: 'teacher-guide/teacher-guide',
        loadChildren:
          './pages/sections/teacher_guide/add-teacher-guide/add-teacher-guide.module#AddTeacherGuideModule',
      },
      {
        path: 'teacher-guide/teacher-guide/:id',
        loadChildren:
          './pages/sections/teacher_guide/add-teacher-guide/add-teacher-guide.module#AddTeacherGuideModule',
      },
      //preparation-notebook
      {
        path: 'preparation-notebook/all-preparation-notebook',
        loadChildren:
          './pages/sections/preparation_notebook/all-preparation-notebook/all-preparation-notebook.module#AllPreparationNotebookModule',
      },
      {
        path: 'preparation-notebook/preparation-notebook',
        loadChildren:
          './pages/sections/preparation_notebook/add-preparation-notebook/add-preparation-notebook.module#AddPreparationNotebookModule',
      },
      {
        path: 'preparation-notebook/preparation-notebook/:id',
        loadChildren:
          './pages/sections/preparation_notebook/add-preparation-notebook/add-preparation-notebook.module#AddPreparationNotebookModule',
      },
      //categories
      {
        path: 'categories/all-categories',
        loadChildren:
          './pages/sections/categories/all-categories/all-categories.module#AllCategoriesModule',
      },
      {
        path: 'categories/category',
        loadChildren:
          './pages/sections/categories/add-category/add-category.module#AddCategoryModule',
      },
      {
        path: 'categories/category/:id',
        loadChildren:
          './pages/sections/categories/add-category/add-category.module#AddCategoryModule',
      },
      //pages_static
      {
        path: 'pages_static/all-pages_static',
        loadChildren:
          './pages/sections/pages_static/all-pages_static/all-pages_static.module#AllPagesStaticModule',
      },
      {
        path: 'pages_static/page_static',
        loadChildren:
          './pages/sections/pages_static/add-page_static/add-page_static.module#AddPageStaticModule',
      },
      {
        path: 'pages_static/page_static/:id',
        loadChildren:
          './pages/sections/pages_static/add-page_static/add-page_static.module#AddPageStaticModule',
      },

      // Main Components
      //pages
      {
        path: 'pages/all-pages',
        loadChildren:
          './pages/main-components/pages/all-pages/all-pages.module#AllPagesModule',
      },
      {
        path: 'pages/page',
        loadChildren:
          './pages/main-components/pages/add-page/add-page.module#AddPageModule',
      },
      {
        path: 'pages/page/:id',
        loadChildren:
          './pages/main-components/pages/add-page/add-page.module#AddPageModule',
      },

      //levels
      {
        path: 'levels/all-levels',
        loadChildren:
          './pages/main-components/levels/all-levels/all-levels.module#AllLevelsModule',
      },
      {
        path: 'levels/level',
        loadChildren:
          './pages/main-components/levels/add-level/add-level.module#AddLevelModule',
      },
      {
        path: 'levels/level/:id',
        loadChildren:
          './pages/main-components/levels/add-level/add-level.module#AddLevelModule',
      },
      //subscriptions
      {
        path: 'subscriptions/all-user-subscriptions',
        loadChildren:
          './pages/sections/subscriptions/all-user-subscriptions/all-user-subscriptions.module#AllUserSubscriptionsModule',
      },

      {
        path: 'subscriptions/show-user-subscriptions/:id/:type',
        loadChildren:
          './pages/sections/subscriptions/show-user-subscription/show-user-subscription.module#ShowUserSubscriptionModule',
      },
      //subscriptions - courses
      {
        path: 'courses_subscriptions/all-subscription-users',
        loadChildren:
          './pages/sections/courses_subscriptions/all-subscription-users/all-subscription-users.module#AllSubscriptionUsersModule',
      },
      //emils
      {
        path: 'emails/add-email',
        loadChildren:
          './pages/sections/emails/add-email/add-email.module#AddEmailModule',
      },
      {
        path: 'emails/edit-email/:id',
        loadChildren:
          './pages/sections/emails/add-email/add-email.module#AddEmailModule',
      },
      {
        path: 'emails/all-emails',
        loadChildren:
          './pages/sections/emails/all-email/all-email.module#AllEmailModule',
      },

      //management
      {
        path: 'management/all-admins',
        loadChildren:
          './pages/main-components/managements/all-admins/all-admins.module#AllAdminsModule',
      },
      {
        path: 'management/admin',
        loadChildren:
          './pages/main-components/managements/add-admin/add-admin.module#AddAdminModule',
      },
      {
        path: 'management/admin/:id',
        loadChildren:
          './pages/main-components/managements/add-admin/add-admin.module#AddAdminModule',
      },

      //members
      {
        path: 'members/all-members',
        loadChildren:
          './pages/main-components/members/all-members/all-members.module#AllMembersModule',
      },
      {
        path: 'members/member',
        loadChildren:
          './pages/main-components/members/add-member/add-member.module#AddMemberModule',
      },
      {
        path: 'members/member/:id',
        loadChildren:
          './pages/main-components/members/add-member/add-member.module#AddMemberModule',
      },
      {
        path: 'members/show-member/:id',
        loadChildren:
          './pages/main-components/members/show-member/show-member.module#ShowMemberModule',
      },

      //settings
      {
        path: 'settings',
        loadChildren:
          './pages/main-components/settings/all-settings/all-settings.module#AllSettingsModule',
      },

      //my-profile
      {
        path: 'my-profile',
        loadChildren:
          './pages/main-components/profile/my-profile/my-profile.module#MyProfileModule',
      },
      {
        path: 'statistics',
        loadChildren:
          './pages/main-components/statistics/statistics.module#statisticsModule',
      },

      // End My Own Pathes

      {
        path: '404',
        loadChildren: './pages/not-found/not-found.module#NotFoundModule',
      },
      {
        path: '',
        redirectTo: 'index',
        pathMatch: 'full',
      },

      {
        path: 'final_reviews/all-notes',
        loadChildren:
          './pages/sections/review_notes/all-notes/all-notes.module#AllNotesModule',
      },
      {
        path: 'final_reviews/note',
        loadChildren:
          './pages/sections/review_notes/add-notes/add-notes.module#AddNotesModule',
      },
      {
        path: 'final_reviews/note/:id',
        loadChildren:
          './pages/sections/review_notes/add-notes/add-notes.module#AddNotesModule',
      },


      {
        path: 'final_reviews/all-videos',
        loadChildren:
          './pages/sections/review_videos/all-videos/all-videos.module#AllVideosModule',
      },
      {
        path: 'final_reviews/video',
        loadChildren:
          './pages/sections/review_videos/add-videos/add-videos.module#AddVideosModule',
      },
      {
        path: 'final_reviews/video/:id',
        loadChildren:
          './pages/sections/review_videos/add-videos/add-videos.module#AddVideosModule',
      },
      {
        path: 'questions_reports/all-reports',
        loadChildren:
          './pages/sections/questions_reports/all-reports/all-reports.module#AllReportsModule',
      },
      {
        path: 'questions_reports/show-question-report/:id',
        loadChildren:
          './pages/sections/questions_reports/show-question-report/show-question-report.module#ShowQuestionReportModule',
      },
      {
        path: 'subjects_costs/all-subjects_costs',
        loadChildren:
          './pages/sections/subjects_costs/all-subjects_costs/all-subjects-costs.module#AllSubjectsCostsModule',
      },
      {
        path: 'subjects_costs/subject_cost',
        loadChildren:
        './pages/sections/subjects_costs/add-subject_costs/add-subject-costs.module#AddSubjectCostsModule',
      },
      {
        path: 'subjects_costs/subject_cost/:id',
        loadChildren:
        './pages/sections/subjects_costs/add-subject_costs/add-subject-costs.module#AddSubjectCostsModule',
      },

      {
        path: 'balance_withdrawal_requests/all-requests',
        loadChildren:
        './pages/sections/balance_withdrawal_requests/all-requests/all-withdrawal-requests.module#AllWithdrawalRequestsModule',
      },


      {
        path: 'balance_withdrawal_requests/show-request/:id',
        loadChildren:
        './pages/sections/balance_withdrawal_requests/show-details/show-details.module#ShowDetailsModule',
      },


    ],
  },

  {
    path: 'login',
    component: LoginComponent,
  },

  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, LoginModule],
})
export class ThemeRoutingModule {
}
