import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {CommonService} from '../../../../_services/common.service';

@Injectable()
export class VisitorBannersService {

  private _url: string = this.commonService._hostName + 'admin-api-v1/sliders.php?action=';

  constructor(public http: HttpClient, public commonService: CommonService) {
  }

  getSomeSliders(start, aItemsPerPage, sort, type, searchName) {
    return this.http.get<any>(this._url + 'getSomeSliders&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchName=' + searchName + '&slider_type=' + 'guest_banners');
  }


  getSearchSlidersCount(sort, type, searchName) {
    return this.http.get<any>(this._url + 'getSearchSlidersCount&sort=' + sort + '&type=' + type + '&searchName=' + searchName + '&slider_type=' + 'guest_banners');
  }

  getSlidersCount() {
    return this.http.get<any>(this._url + 'getSlidersCount&slider_type=' + 'guest_banners');
  }


  addEditSliderGuestBanners(formData) {
    return this.http.post<any>(this._url + 'addEditSliderGuestBanners', formData);
  }

  deleteSlider(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteSlider', body);
  }

  deleteFileInDataBase(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteFileInDataBase', body);
  }

  getOneSlider(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getOneSlider', body);
  }


}
